.GetBox {}
.GetBox button{
    padding: 10px 15px;
    border-radius: 20px;
    margin:15px 70px 0px;
}
.userBox{
    margin: 20px;
}
.get-container{
    width: 49%;
    height: 70vh;
    float: left;
    overflow: scroll;
}
