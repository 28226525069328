.PostBox {}

#post-form{
width: 45%;
margin-right: 5%;
float: left;
}
#post-form p{
margin-bottom: 0;
}
#post-form input{
width: 97%;
padding: 7px;
border-radius: 4px;
}
#post-form textarea{
width: 97%;
padding: 7px;
border-radius: 4px;
resize: none;
}
#post-form button{
padding: 10px 25px;
border-radius: 30px;
}